import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const CTA = makeShortcode("CTA");
const List = makeShortcode("List");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Vaults`}</h1>
    <InfoBlock mdxType="InfoBlock">
      <p><img parentName="p" {...{
          "src": "/images/illustrations/Astro-2.png",
          "alt": "Vaults"
        }}></img></p>
      <Box mdxType="Box">
        <h2>{`A Vault Is a Tool That Lets the Owner Deposit Collateral and Generate Dai`}</h2>
      </Box>
    </InfoBlock>
    <p>{`Users create Dai by generating it against their collateral and destroy Dai when repaying their generated Dai balance. Vaults are categorized by the type of collateral used to generated Dai.`}</p>
    <CTA mdxType="CTA">
      <p><a parentName="p" {...{
          "href": "/learn/vaults/vaults-tutorial/"
        }}>{`Click here to learn how to use Vaults`}</a></p>
    </CTA>
    <Box sx={{
      padding: 4
    }} mdxType="Box">
      <h2>{`Sections`}</h2>
      <List mdxType="List">
        <p><a parentName="p" {...{
            "href": "/learn/vaults/vault-onboarding-guide/"
          }}>{`Vault Onboarding`}</a></p>
        <p><a parentName="p" {...{
            "href": "/learn/vaults/stability-fees/"
          }}>{`Stability Fees`}</a></p>
        <p><a parentName="p" {...{
            "href": "/learn/vaults/liquidation/"
          }}>{`Liquidation`}</a></p>
        <p><a parentName="p" {...{
            "href": "/learn/vaults/risk/"
          }}>{`Risk`}</a></p>
      </List>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      